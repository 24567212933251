.admin-stats {
  text-align: center;
}

.ant-table {
  border-radius: .5em;
  overflow: hidden;
  border: solid #d7d7d7 1px;
}

.admin-media {
  width: 75vw;
  margin: auto;
  text-align: left;

  .select-item {
    text-align: left;
    width: 60vw !important;
    margin: auto;
  }
}

.steps-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5vh;
  min-height: 200px;
  margin-top: 16px;
  text-align: center;
  background-color: #fafafa;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
}

.steps-action {
  margin-top: 24px;
}

.admin-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 0;

  @media (max-width: 500px) {
    flex-direction: column;
    padding: 0.25em 0;
  }
}

.fixed-button {
  background-color: rgb(255, 255, 255);
  border: solid #1d4a99 1px;
  font-weight: 500;
  // box-shadow: 4px 2px 6px #d8dadd,
  //     -4px -2px 6px #f0eeee;
  margin-right: 10px;

  &:hover {
    // background: #f5f8fc;
    color: #1d4a99;
    // border: solid #f1eeee 2px;
    // box-shadow: 2px 1px 4px #d8dadd,
    //     -2px -1px 4px #f0eeee;
  }
}

.ant-table-thead > tr > th {
  //color: #ffffff;
  font-weight: bold;
  //background-color: #1d4a99 !important;
}

#admin,
#user {
  .anticon {
    font-size: large !important;
  }
}


#item {
  & > .nav-bar {
    display: flex;
    justify-content: space-between;

    & > div {
      & > button:nth-child(1) {
        font-size: 2rem;
      }

      & > button:nth-child(2) {
        font-size: 2rem;
      }
    }
  }

  .grid {
    row-gap: 1vh !important;
  }
}

.folder {
  transition: .5s ease-in-out;
  margin: 0 10px;
  // padding: 2vh;

  &-item {
    font-size: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1em;
    overflow: hidden;
  }

  & > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > span {
      font-size: 3rem;
    }
  }

  & > div:nth-child(2) {
    display: flex;
    justify-content: center;
    opacity: 0;
    transition: 1s;

    button {
      margin: 0 10px;
      padding: 0;
    }
  }

  & > p {
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }

  &:hover {
    // background: #f0f2f5;
    // box-shadow: inset 5px 5px 10px #e6e8eb,
    //     inset -5px -5px 10px #fafcff;
    cursor: pointer;

    & > div:nth-child(2) {
      opacity: 1;
    }
  }
}

.mobile-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em 0;

  &-item {
    background: #ffffff;
    margin-top: 1em;
    border-radius: 1em;
    padding-top: 1em;
    overflow: hidden;
    box-shadow: 0 0 6px 2px #dadada;


    &:nth-child(odd) {
      background: #1d4a99;
      color: white;

      a {
        color: #edf06a !important;
      }
    }

    &-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2px 2em 2px 2em;
    }

    &-action {
      background-color: #ffffff;
      justify-content: flex-end;
      border-top: 1px ridge rgb(192, 192, 192);

      p {
        width: auto;
        margin: 0;

        @media (max-width: 500px) {
          width: 60%;
        }
      }
    }
  }
}

.item-cell {
  overflow: scroll;
  overflow-y: hidden;
  width: 100%;
  display: flex;
}

.permission-select {
  & > div {
    display: block !important;
    height: 150px !important;
    overflow: auto !important;
  }
}

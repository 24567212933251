.info {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 2rem;
  color: #1d4a99;
  font-weight: bold;
  background-color: transparent;

  &:hover {
    cursor: pointer;
  }
}

.ant-menu-vertical > .ant-menu-item {
  display: flex;
}

.ant-tooltip-inner {
  color: #dcdcdc;
  width: auto;
}

.ant-menu-item-icon{
  font-size: 1.1rem !important;
}

.ant-message {
  z-index: 10000;
}

.dx-datagrid .dx-row-alt > td, .dx-datagrid .dx-row-alt > tr > td {
  background: #1d4a9947 !important;
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):not(.dx-row-lines) > td {
  background: #1d4a99 !important;
  color: white !important;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  background: #1d4a99 !important;
  color: white;
}

.dx-texteditor.dx-editor-outlined {
  border-radius: 0 !important;
}

.help {
  color: black;

  &:hover {
    background: #F1F1F1D6;
    transform: rotate(180deg);
  }
}

#order > div:nth-child(4) > div.ant-col.ant-form-item-control > div > div > div > div > span.ant-picker-clear > span {
  display: none !important;
}

.ant-form-item-label > label {
  display: flex !important;
}

.ant-layout {
  background-color: #ffffff;
}

.main-content {
  padding: calc(3vh + 1vw);
  overflow: auto;
  height: 100vh;
}

.ant-modal {
  border-radius: 1em;
  background-color: #1d4a99;
  padding-bottom: 1em !important;
}

.ant-modal-content {
  border-radius: 1em 1em 0 0;
  overflow: hidden;
}

.ant-layout-sider-collapsed {
  @media (max-width: 1024px) {
    flex: 0 0 60px !important;
    max-width: 60px !important;
    min-width: 60px !important;
    width: 60px !important;
  }
}


.trigger {
  position: fixed;
  left: 80px;
  top: 0;
  background: none;
  border: none;
  @media (max-width: 1024px) {
    left: 60px;
  }
}

.side-bar {
  background: #ffffff0c !important;
  border-right: solid #d7d7d7 1px;
}

.ant-menu-item {
  display: flex;
  align-items: center;
  height: 60px !important;
  margin: 0 !important;
}

.selected{
  background: #1d4a99 !important;
  color: white !important;

  &:hover {
    color: #dbdfe7 !important;
  }
}

body > div > div > div > div > div.ant-tooltip-inner > a {
  color: white;
}

.sidebar-logo {
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.logout_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  & > button {
    margin: 1vh 0;
    width: 40vw;
    height: 5vh;
  }
}

#mobile {
  z-index: 5000;
  display: flex;
  position: fixed;
  top: 0;
  justify-content: space-between;
  background-color: #fafafa;
  width: 100%;

  .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 0;
    padding: 2vh;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      flex-shrink: 0;
      min-width: 80%;
      min-height: 100%;
      width: 100%;
    }
  }

  .dropdown-icon {
    z-index: 1000000;
    color: black;
    font-size: 1.5rem;
  }
}

.ant-dropdown {
  position: relative !important;
  width: 100%;
}

#mobile-menu {
  z-index: 6000;
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.738), rgba(255, 255, 255, 0.471));
  background: -webkit-linear-gradient(to right bottom, rgba(255, 255, 255, 0.363), rgba(255, 255, 255, 0.205));
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);

  & > li {
    width: 100%;
    height: 60px;
    background: transparent;
  }

  & > li > span {
    flex: 4;
    font-size: larger !important;

    &:nth-child(1) {
      flex: 3;
      svg {
        float: right;
        margin-right: 3vw;
      }
    }
  }

  // &>li>span.ant-dropdown-menu-title-content>a {
  //     font-size: larger !important;
  // }
}

.thumbnail-background {
  position: relative;
  display: flex;
  align-items: center;
  height: 100px;
  //width: 100%;
  padding: 2rem;
  border-radius: 1em;
  overflow: hidden;

  &-style {
    background-color: rgba(255, 255, 255, 0.061);
    // box-shadow: 0px 0px 4px 1px #e3e5e7;
    //border: solid rgba(128, 128, 128, 0.272) 1px;
  }

  &-tag {
    position: absolute;
    background-color: #1d4a99;
    width: 70%;
    border-radius: 0 0 0 1em;
    text-align: center;
    color: whitesmoke;
    top: 0;
    right: 0;
  }
}

.thumbnail {
  width: -webkit-fill-available;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
  transition: transform .2s;
  // &:hover {
  //     object-fit: cover;
  // }
}

.thumbnail-placeholder {
  width: -webkit-fill-available;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
  transition: all 1s 2s ease-in-out;
}

.login {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;

  .ant-form-item-control-input-content {
    display: flex !important;
    justify-content: center;
  }

  &-main {
    background: #ffffff;
    background: -moz-linear-gradient(top, #ffffff 30%, #1d4a99 50%);
    background: -webkit-linear-gradient(top, #ffffff 30%, #1d4a99 50%);
    background: linear-gradient(to bottom, #ffffff 30%, #1d4a99 50%);
    //filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr=#1d4a99, GradientType=0);
    display: flex;
    flex-direction: column;
    height: 80vh;
    width: 30vw;
    overflow: hidden;
    border-radius: 1em;
    box-shadow: 0px -2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
    @media (max-width: 767px) {
      height: 100vh;
      width: 100vw;
      border-radius: 0;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      height: 80vh;
      width: 60vw;
    }
    @media (min-width: 1024px) and (max-width: 1366px) {
      width: 60vw;
    }

    &-top {
      z-index: 1;
      display: flex;
      flex-direction: column;
      flex: 3;
      padding: 4vh 3vh;
      background-color: white;
      border-radius: 0 0 2em 2em;
      box-shadow: 0 4px 4px 3px rgba(29, 1, 1, 0.523);

      .login-logo {
        display: flex;
        flex: 2;
        align-items: center;
        justify-content: center;

        img {
          width: 80%;
        }
      }

      input {
        // width: 30vw;
        height: 40px;
        @media (max-width: 769px) {
          width: 80vw;
        }
      }
    }

    &-bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;
      padding: 4vh 2vh 2vh 2vh;
      background-color: #1d4a99;
      width: 100%;

      div {
        width: 100%;
      }

      button {
        width: 90%;
        height: 50px;
        font-size: medium;
        font-weight: 700;
      }
    }
  }
}

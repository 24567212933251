.profile-card {
    display: flex;
    flex-direction: column;
    height: 80vh;
    width: 60vw;
    padding: 2vh;
    margin: auto;
    align-items: center;
    justify-content: center;
    border-radius: 2em;

    &-img {
        display: flex;
        justify-content: center;
        overflow: hidden;

        &>img {
            max-height: 100%;
            object-fit: cover;
        }
    }

    &-tabs {
        flex: 2;
        text-align: center;
    }
}

#changePassword,
#tab-1 {
    margin-top: 2vh;

    .ant-row {
        justify-content: space-between !important;
    }
}
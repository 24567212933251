.search {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.results {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 1vh;
    margin-top: 2vh;
    &-list {
        width: 90%;
        margin: auto;
        @media (max-width: 1024px) {
            width: 95%;
        }
        &-item {
            display: flex;
            margin: 1vh;
            border: solid 1px #d7d7d7;
            justify-content: space-between;
            border-radius: 0 1em 0 1em;
            overflow: hidden;

            @media (max-width: 1024px) {
                flex-direction: column;
                align-items: flex-start;
                border-radius: 1em;
            }

            &-detail {
                padding: 1em;
                flex: 6;

                &>h2{
                    color: #1d4a99;
                }

                &-link {
                    margin: 0 0 0 10px;
                    color: grey;
                    font-size: smaller;
                }
            }
            &-type {
                flex: 1;
                background: #1d4a99;
                display: flex;
                align-items: center;
                @media (max-width: 1024px) {
                    width: 100%;
                    height: 4vh;
                }
                h3 {
                    text-align: center;
                    width: 100%;
                    padding: 0 1rem;
                    margin: 0;
                    color: white;
                }
            }

            &:hover{
                background-color: rgba(0, 0, 0, 0.02) !important;
            }
        }
    }
}

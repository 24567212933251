#customer {
  // .ant-col {
  //     width: 25%;
  // }

  .order-link {
    cursor: pointer;
    color: #1d4a99;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: large;
  }

  #customer-order-detail-table {
    width: 100%;

    th,
    td {
      padding: 0 10px;
      text-align: left;
    }
  }
}

.customer-order-detail-card {
  background-color: #ffffff;
  border-radius: .5em;
  //border: solid #d7d7d7 1px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: box-shadow 500ms;

  .ant-card-head {
    //background-color: #1d4a99;
    //color: #f0f2f5;
    min-height: unset !important;
    padding: 0 !important;
  }

  .ant-card-head-title {
    padding: 10px 2vw !important;
    // font-size: larger;
  }

  .ant-card-body {
    padding: 1em !important;
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.02) !important;
    //box-shadow: inset 5px 5px 10px #e6e8eb,
    //    inset -5px -5px 10px #fafcff;

  }
}

.ant-page-header-heading-left {
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

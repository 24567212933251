.info {
  color: #1d4a99;
  background-color: #0000;
  font-size: 2rem;
  font-weight: bold;
  position: absolute;
  top: 0;
  right: 10px;
}

.info:hover {
  cursor: pointer;
}

.ant-menu-vertical > .ant-menu-item {
  display: flex;
}

.ant-tooltip-inner {
  color: #dcdcdc;
  width: auto;
}

.ant-menu-item-icon {
  font-size: 1.1rem !important;
}

.ant-message {
  z-index: 10000;
}

.dx-datagrid .dx-row-alt > td, .dx-datagrid .dx-row-alt > tr > td {
  background: #1d4a9947 !important;
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):not(.dx-row-lines) > td {
  color: #fff !important;
  background: #1d4a99 !important;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  color: #fff;
  background: #1d4a99 !important;
}

.dx-texteditor.dx-editor-outlined {
  border-radius: 0 !important;
}

.help {
  color: #000;
}

.help:hover {
  background: #f1f1f1d6;
  transform: rotate(180deg);
}

#order > div:nth-child(4) > div.ant-col.ant-form-item-control > div > div > div > div > span.ant-picker-clear > span {
  display: none !important;
}

.ant-form-item-label > label {
  display: flex !important;
}

.ant-layout {
  background-color: #fff;
}

.main-content {
  height: 100vh;
  padding: calc(3vh + 1vw);
  overflow: auto;
}

.ant-modal {
  background-color: #1d4a99;
  border-radius: 1em;
  padding-bottom: 1em !important;
}

.ant-modal-content {
  border-radius: 1em 1em 0 0;
  overflow: hidden;
}

@media (width <= 1024px) {
  .ant-layout-sider-collapsed {
    flex: 0 0 60px !important;
    width: 60px !important;
    min-width: 60px !important;
    max-width: 60px !important;
  }
}

.trigger {
  background: none;
  border: none;
  position: fixed;
  top: 0;
  left: 80px;
}

@media (width <= 1024px) {
  .trigger {
    left: 60px;
  }
}

.side-bar {
  border-right: 1px solid #d7d7d7;
  background: #ffffff0c !important;
}

.ant-menu-item {
  align-items: center;
  display: flex;
  height: 60px !important;
  margin: 0 !important;
}

.selected {
  color: #fff !important;
  background: #1d4a99 !important;
}

.selected:hover {
  color: #dbdfe7 !important;
}

body > div > div > div > div > div.ant-tooltip-inner > a {
  color: #fff;
}

.sidebar-logo {
  width: 100%;
  position: absolute;
  bottom: 10px;
}

.logout_container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
}

.logout_container > button {
  width: 40vw;
  height: 5vh;
  margin: 1vh 0;
}

#mobile {
  z-index: 5000;
  background-color: #fafafa;
  justify-content: space-between;
  width: 100%;
  display: flex;
  position: fixed;
  top: 0;
}

#mobile .logo {
  float: left;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 31px;
  margin: 16px 0;
  padding: 2vh;
  display: flex;
}

#mobile .logo img {
  flex-shrink: 0;
  width: 100%;
  min-width: 80%;
  min-height: 100%;
}

#mobile .dropdown-icon {
  z-index: 1000000;
  color: #000;
  font-size: 1.5rem;
}

.ant-dropdown {
  width: 100%;
  position: relative !important;
}

#mobile-menu {
  z-index: 6000;
  background: linear-gradient(to bottom right, #ffffffbc, #ffffff78);
  background: -webkit-linear-gradient(to right bottom, #ffffff5d, #ffffff34);
  -webkit-backdrop-filter: blur(30px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
}

#mobile-menu > li {
  background: none;
  width: 100%;
  height: 60px;
}

#mobile-menu > li > span {
  flex: 4;
  font-size: larger !important;
}

#mobile-menu > li > span:first-child {
  flex: 3;
}

#mobile-menu > li > span:first-child svg {
  float: right;
  margin-right: 3vw;
}

.thumbnail-background {
  border-radius: 1em;
  align-items: center;
  height: 100px;
  padding: 2rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

.thumbnail-background-style {
  background-color: #ffffff10;
}

.thumbnail-background-tag {
  text-align: center;
  color: #f5f5f5;
  background-color: #1d4a99;
  border-radius: 0 0 0 1em;
  width: 70%;
  position: absolute;
  top: 0;
  right: 0;
}

.thumbnail {
  object-fit: contain;
  width: -webkit-fill-available;
  height: 100%;
  transition: transform .2s;
  overflow: hidden;
}

.thumbnail-placeholder {
  object-fit: contain;
  width: -webkit-fill-available;
  height: 100%;
  transition: all 1s ease-in-out 2s;
  overflow: hidden;
}

.login {
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
}

.login .ant-form-item-control-input-content {
  justify-content: center;
  display: flex !important;
}

.login-main {
  background: linear-gradient(#fff 30%, #1d4a99 50%);
  border-radius: 1em;
  flex-direction: column;
  width: 30vw;
  height: 80vh;
  display: flex;
  overflow: hidden;
  box-shadow: 0 -2.8px 2.2px #00000009, 0 6.7px 5.3px #0000000c, 0 12.5px 10px #0000000f, 0 22.3px 17.9px #00000012, 0 41.8px 33.4px #00000016, 0 100px 80px #0000001f;
}

@media (width <= 767px) {
  .login-main {
    border-radius: 0;
    width: 100vw;
    height: 100vh;
  }
}

@media (width >= 768px) and (width <= 1023px) {
  .login-main {
    width: 60vw;
    height: 80vh;
  }
}

@media (width >= 1024px) and (width <= 1366px) {
  .login-main {
    width: 60vw;
  }
}

.login-main-top {
  z-index: 1;
  background-color: #fff;
  border-radius: 0 0 2em 2em;
  flex-direction: column;
  flex: 3;
  padding: 4vh 3vh;
  display: flex;
  box-shadow: 0 4px 4px 3px #1d010185;
}

.login-main-top .login-logo {
  flex: 2;
  justify-content: center;
  align-items: center;
  display: flex;
}

.login-main-top .login-logo img {
  width: 80%;
}

.login-main-top input {
  height: 40px;
}

@media (width <= 769px) {
  .login-main-top input {
    width: 80vw;
  }
}

.login-main-bottom {
  background-color: #1d4a99;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 4vh 2vh 2vh;
  display: flex;
}

.login-main-bottom div {
  width: 100%;
}

.login-main-bottom button {
  width: 90%;
  height: 50px;
  font-size: medium;
  font-weight: 700;
}

.search {
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.results {
  justify-content: space-evenly;
  align-items: center;
  margin-top: 2vh;
  margin-bottom: 1vh;
  display: flex;
}

.results-list {
  width: 90%;
  margin: auto;
}

@media (width <= 1024px) {
  .results-list {
    width: 95%;
  }
}

.results-list-item {
  border: 1px solid #d7d7d7;
  border-radius: 0 1em;
  justify-content: space-between;
  margin: 1vh;
  display: flex;
  overflow: hidden;
}

@media (width <= 1024px) {
  .results-list-item {
    border-radius: 1em;
    flex-direction: column;
    align-items: flex-start;
  }
}

.results-list-item-detail {
  flex: 6;
  padding: 1em;
}

.results-list-item-detail > h2 {
  color: #1d4a99;
}

.results-list-item-detail-link {
  color: gray;
  margin: 0 0 0 10px;
  font-size: smaller;
}

.results-list-item-type {
  background: #1d4a99;
  flex: 1;
  align-items: center;
  display: flex;
}

@media (width <= 1024px) {
  .results-list-item-type {
    width: 100%;
    height: 4vh;
  }
}

.results-list-item-type h3 {
  text-align: center;
  color: #fff;
  width: 100%;
  margin: 0;
  padding: 0 1rem;
}

.results-list-item:hover {
  background-color: #00000005 !important;
}

#customer .order-link {
  cursor: pointer;
  color: #1d4a99;
  font-family: Franklin Gothic Medium, Arial Narrow, Arial, sans-serif;
  font-size: large;
}

#customer #customer-order-detail-table {
  width: 100%;
}

#customer #customer-order-detail-table th, #customer #customer-order-detail-table td {
  text-align: left;
  padding: 0 10px;
}

.customer-order-detail-card {
  background-color: #fff;
  border-radius: .5em;
  transition: box-shadow .5s;
  overflow: hidden;
  box-shadow: 0 0 2px 1px #0000001a;
}

.customer-order-detail-card .ant-card-head {
  min-height: unset !important;
  padding: 0 !important;
}

.customer-order-detail-card .ant-card-head-title {
  padding: 10px 2vw !important;
}

.customer-order-detail-card .ant-card-body {
  padding: 1em !important;
}

.customer-order-detail-card:hover {
  cursor: pointer;
  background-color: #00000005 !important;
}

@media (width <= 1024px) {
  .ant-page-header-heading-left {
    flex-direction: column;
    align-items: flex-start;
  }
}

.profile-card {
  border-radius: 2em;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60vw;
  height: 80vh;
  margin: auto;
  padding: 2vh;
  display: flex;
}

.profile-card-img {
  justify-content: center;
  display: flex;
  overflow: hidden;
}

.profile-card-img > img {
  object-fit: cover;
  max-height: 100%;
}

.profile-card-tabs {
  text-align: center;
  flex: 2;
}

#changePassword, #tab-1 {
  margin-top: 2vh;
}

#changePassword .ant-row, #tab-1 .ant-row {
  justify-content: space-between !important;
}

.admin-stats {
  text-align: center;
}

.ant-table {
  border: 1px solid #d7d7d7;
  border-radius: .5em;
  overflow: hidden;
}

.admin-media {
  text-align: left;
  width: 75vw;
  margin: auto;
}

.admin-media .select-item {
  text-align: left;
  margin: auto;
  width: 60vw !important;
}

.steps-content {
  text-align: center;
  background-color: #fafafa;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  margin-top: 16px;
  padding: 5vh;
  display: flex;
}

.steps-action {
  margin-top: 24px;
}

.admin-title {
  justify-content: space-between;
  align-items: center;
  padding: .5em 0;
  display: flex;
}

@media (width <= 500px) {
  .admin-title {
    flex-direction: column;
    padding: .25em 0;
  }
}

.fixed-button {
  background-color: #fff;
  border: 1px solid #1d4a99;
  margin-right: 10px;
  font-weight: 500;
}

.fixed-button:hover {
  color: #1d4a99;
}

.ant-table-thead > tr > th {
  font-weight: bold;
}

#admin .anticon, #user .anticon {
  font-size: large !important;
}

#item > .nav-bar {
  justify-content: space-between;
  display: flex;
}

#item > .nav-bar > div > button:first-child, #item > .nav-bar > div > button:nth-child(2) {
  font-size: 2rem;
}

#item .grid {
  row-gap: 1vh !important;
}

.folder {
  margin: 0 10px;
  transition: all .5s ease-in-out;
}

.folder-item {
  border-radius: 1em;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  display: flex;
  overflow: hidden;
}

.folder > div:first-child {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.folder > div:first-child > span {
  font-size: 3rem;
}

.folder > div:nth-child(2) {
  opacity: 0;
  justify-content: center;
  transition: all 1s;
  display: flex;
}

.folder > div:nth-child(2) button {
  margin: 0 10px;
  padding: 0;
}

.folder > p {
  text-align: center;
  width: 100%;
  margin-top: 10px;
}

.folder:hover {
  cursor: pointer;
}

.folder:hover > div:nth-child(2) {
  opacity: 1;
}

.mobile-table {
  flex-direction: column;
  align-items: center;
  padding: 2em 0;
  display: flex;
}

.mobile-table-item {
  background: #fff;
  border-radius: 1em;
  margin-top: 1em;
  padding-top: 1em;
  overflow: hidden;
  box-shadow: 0 0 6px 2px #dadada;
}

.mobile-table-item:nth-child(odd) {
  color: #fff;
  background: #1d4a99;
}

.mobile-table-item:nth-child(odd) a {
  color: #edf06a !important;
}

.mobile-table-item-row {
  justify-content: space-between;
  align-items: center;
  padding: 2px 2em;
  display: flex;
}

.mobile-table-item-action {
  background-color: #fff;
  border-top: 1px ridge silver;
  justify-content: flex-end;
}

.mobile-table-item-action p {
  width: auto;
  margin: 0;
}

@media (width <= 500px) {
  .mobile-table-item-action p {
    width: 60%;
  }
}

.item-cell {
  width: 100%;
  display: flex;
  overflow: scroll hidden;
}

.permission-select > div {
  height: 150px !important;
  display: block !important;
  overflow: auto !important;
}

.preloader {
  z-index: 9000;
  background-color: #0004;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  transition: all 1s;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.preloader-mobileTable {
  z-index: 9000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  transition: all 1s;
  display: flex;
  top: 0;
  left: 0;
  position: absolute !important;
}

.lds-ring {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.lds-ring div {
  box-sizing: border-box;
  border: 15px solid #0000;
  border-top-color: #1d4a99;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin: 8px;
  animation: 1.2s cubic-bezier(.5, 0, .5, 1) infinite lds-ring;
  display: block;
  position: absolute;
}

.lds-ring div:first-child {
  animation-delay: -.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

* {
  font-family: Arial, Montserrat, sans-serif;
}
/*# sourceMappingURL=index.f7f6a641.css.map */

@forward 'main';
@forward 'search';
@forward "customer";
@forward 'profile';
@forward 'admin';
@forward 'loader';

* {
    font-family: Arial,'Montserrat', sans-serif;
}
